<template>
  <form @submit.prevent="submit">
    <loading-overlay
      :active="Loading"
      :is-full-page="true"
      loader="bars"
    />
    <CModalExtended
      :title="title"
      color="dark"
      :show.sync="modalActive"
      :closeOnBackdrop="false"
      @update:show="resetForm"
    >
      <CRow>
        <CCol sm="12" lg="12">
          <div class="form-group form-row">
            <label class="required col-form-label-sm col-sm-12 col-lg-4 mb-0 text-right">{{$t('label.machinery')}}</label>
            <div class="input-group col-sm-12 col-lg-8">
              
                <v-select
                  class="mb-0 px-0 col-sm-10"
                  id="v-select-small"
                  :placeholder="$t('label.select')"
                  :options="machineOptions"
                  :reduce="option => option.value" 
                  v-model="$v.Machines.MachineId.$model"
                  :is-valid="hasError($v.Machines.MachineId)"
                  :invalid-feedback="errorMessage($v.Machines.MachineId)"
                  :addInputClasses="'not-border'"
                  style="height: 29px;"
                >
                    <template #no-options="{}">
                        {{$t('label.noResultsFound')}}
                    </template>
                    <template slot="option" slot-scope="option" >
                      <span>
                        &nbsp;
                      </span>              
                      {{ option.label }}
                  </template>
                </v-select>
            </div>
          </div>
        </CCol>
        <CCol sm="12" lg="12" v-if="edit">
          <CSelect
            :options="statusOptions"
            :value.sync="Machines.Status"
            :label="$t('label.status')"
            :horizontal="{label:'col-sm-12 col-lg-3', input:'col-sm-12 col-lg-8'}"
            addLabelClasses="required text-right"
            :is-valid="statusSelectColor"
          />
        </CCol>
      </CRow>
      <template #footer>
        <CButton
          square
          color="add"
          class="d-flex align-items-center"
          :disabled="isSubmit"
          @click.stop="edit ? statusConfirmation(machineItems.FgActMachineYard, Machines.Status, submit) : submit()"
        >
        <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          square
          color="wipe"
          class="d-flex align-items-center"
          @click="resetForm"
        >
          <CIcon name="x" /><span class="ml-1">{{$t('button.cancel')}}</span>
        </CButton>
    </template>
    </CModalExtended>
  </form>
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import UpperCase from '@/_validations/uppercase-directive';
import { FormMachines } from '@/_validations/master-yards/MasterOfYards';
import { mapState } from "vuex";
import NumberInput from '@/components/numberinput';

function data() {
  return {
    isSubmit: false,
    modalActive: false,
    Loading: false,
    Machines: {
      MachineId: '',
      MachineYardId: '',
      Status: 0,
    },
    machinery: [],
  };
}

function submit() {
  try {
    this.isSubmit = true;
    this.Loading = true;
    this.$v.$touch();
    if (this.$v.$error) {
      this.isSubmit = false;
      this.Loading = false;
      throw this.$t('label.errorsPleaseCheck');
    }

    let MachineYardJson = this.edit ? {
      MachineYardId: this.machineId,
      MachineId: this.Machines.MachineId,
      YardId: this.getYardId,
      Status: this.Machines.Status,
    } : {
      MachineId: this.Machines.MachineId,
      YardId: this.getYardId,
    }

    let metodo = this.edit ? 'PUT':'POST';
    let ruta = this.edit ? 'MachineYard-update': 'MachineYard-insert';
    this.$http
      .ejecutar( metodo, ruta, MachineYardJson, { root: 'MachineYardJson' })
      .then((response) => {
        if (response && response.status === (200 || 201)) {
          this.$emit('submited');
          this.getYards();
          this.modalActive = false;
          this.notifySuccess({text: response.data.data[0].Response});
          this.resetForm();
        } 
      }).catch((e) => {
        this.notifyError({text: e});
      }).then(() => {
        this.isSubmit = false;
        this.Loading = false;
      });
  } catch (e) {
    this.isSubmit = false;
    this.Loading = false;
    this.notifyError({text: e});
  }
}

function getYards() {
  this.Loading = true;
  this.$http
    .get('Yard-list',{ CompanyBranchId: this.$store.state.auth.branch.CompanyBranchId})
    .then(response => {
      let listado = response.data.data;
      if (listado && listado.length != 0){
        let yardItem = listado.find((item)=> item.YardId === this.$store.state.yard.yardSelected.YardId);

        if(yardItem)
          this.$store.state.yard.yardSelected = yardItem;
      }
    })        
    .finally(() => {
      this.Loading = false;
    });
}


function toggle(newVal) {
  if (!newVal)
    this.resetForm();
  else{
    if (this.edit) {
        this.getData(this.machineItems);
    }
  }
  this.modalActive = newVal;
}
function getListMaquinary(){
  this.Loading = true;
  this.$http.get("Machine-by-CompanyBranchId",{CompanyBranchId:this.getBranchId})
    .then(response => {
      this.machinery = response.data.data;
      this.Loading = false;
    }).catch( err => {
        this.$notify({
          group: 'container',
          title: '¡Error!',
          text: err,
          type: "error"
        });
        this.Loading = false;
      });
}
function statusSelectColor() {
  return this.Machines.Status === 1;
}

function getData(val) {
  this.Machines.MachineId = val.MachineId;
  this.Machines.Status = val.FgActMachineYard?1:0;
  this.$v.$touch();
}

function resetForm() {
  this.Machines.MachineId = '';
  this.Machines.MachineYardId = '';
  this.Machines.Status = 0;
  this.machinery = [];
  this.$emit('close');
  this.$v.$reset();
}

//computed
function machineOptions(){
  if(this.machinery.length === 0){
    return [{
      value: '', 
      label: this.$t('label.select'),
    }];
  }else{
    var chart = [{
      value: '', 
      label: this.$t('label.select'),
    }]
    this.machinery.map((e)=>{
      chart.push({
        value: e.MachineId, 
        label: e.MachineName,
      })    
    })
    return chart;
  }
}
function getYardId() {
  return this.$store.state.yard.yardSelected.YardId ? this.$store.state.yard.yardSelected.YardId: "";
}
function getBranchId() {
  return this.branch ? this.branch.CompanyBranchId : "";
}
export default {
  name: 'machine-modal',
  props: {
    modal: Boolean,
    edit: {
      type: Boolean,
      default: false,
    },
    machineId: {
      type: String,
      default: "",
    },
    machineItems: {
      type: Object,
      default: "",
    },
    title:{
      type: String,
      default: "",
    }

  },
  data,
  validations(){ return FormMachines() },
  components: {},
  mixins: [
    ModalMixin,
  ],
  directives: UpperCase,
  methods: {
    getListMaquinary,
    statusSelectColor,
    getData,
    submit,
    toggle,
    resetForm,
    getYards
  },
  computed: {
    machineOptions,
    getYardId,
    getBranchId,
    ...mapState({
      branch: (state) => state.auth.branch,
      yardSelected: state => state.yard.yardSelected
    }),
  },
  watch: {
    modal: function(val){
      this.toggle(val);
      if (val) {
        this.getListMaquinary();
      }
    },
    modalActive: function (val) {
      this.$emit('update:modal', val);
    }
  },
  
};
</script>